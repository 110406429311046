<template>
  <div class="flex space-x-1 xs:space-x-2 sm:space-x-2 justify-center">
    <input
      v-for="index in length"
      :key="index"
      data-testid="reference-code"
      :id="`reference-code-${index - 1}`"
      type="text"
      maxlength="1"
      :class="[
        'block w-10 h-10 text-center border rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-lg',
        error ? 'border-red-500' : 'border-gray-400'
      ]"
      :value="referenceCode[index - 1] || ''"
      @input="handleInput($event, index - 1)"
      @keydown="handleKeydown($event, index - 1)"
      @paste="handlePaste"
      @focus="focusInput(index - 1)"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, nextTick } from 'vue';
import { storeToRefs } from 'pinia';
import { useWebsiteStore } from '~/stores/website';

const props = defineProps({
  length: { type: Number, default: 7 },
  error: { type: Boolean, default: false }
});

const { referenceCode } = storeToRefs(useWebsiteStore());

onMounted(() => focusCorrectInput);

const handleInput = (event: Event, index: number) => {
  const input = event.target as HTMLInputElement;
  referenceCode.value = referenceCode.value.slice(0, index) + input.value.toUpperCase() + referenceCode.value.slice(index + 1);
  nextTick(focusCorrectInput);
};

const focusInput = (index: number) => {
  nextTick(() => {
    const input = document.getElementById(`reference-code-${index}`) as HTMLInputElement;
    input?.setSelectionRange(input.value.length, input.value.length);
  });
};

const focusCorrectInput = () => {
  const nextInputIndex = Math.min(referenceCode.value.trim().length, props.length - 1);
  (document.getElementById(`reference-code-${nextInputIndex}`) as HTMLInputElement)?.focus();
};

const handleKeydown = (event: KeyboardEvent, index: number) => {
  switch (event.key) {
    case 'Backspace':
      if (index > 0 && !referenceCode.value[index]) {
        event.preventDefault();
        referenceCode.value = referenceCode.value.slice(0, index - 1) + referenceCode.value.slice(index);
        focusCorrectInput();
      }
      break;
    case 'ArrowLeft':
    case 'ArrowRight':
      event.preventDefault();
      const nextIndex = event.key === 'ArrowLeft' ? Math.max(0, index - 1) : Math.min(props.length - 1, index + 1);
      (document.getElementById(`reference-code-${nextIndex}`) as HTMLInputElement)?.focus();
      break;
  }
};

const handlePaste = (event: ClipboardEvent) => {
  event.preventDefault();
  const pastedText = event.clipboardData?.getData('text').slice(0, props.length).toUpperCase();
  if (pastedText) {
    referenceCode.value = pastedText.padEnd(props.length, ' ');
    focusCorrectInput();
  }
};
</script>
